import React, { useState, useEffect } from "react";
import { Form, Button, message, Checkbox } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import fetchData from "../services/requester";

const MakeComplimentary = (props) => {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const layout = {
    labelCol: {
      span: 22,
    },
    wrapperCol: {
      span: 22,
    },
  };

  const onFinish = (values) => {
    setLoading(true);
    let reqURL = "requests/complimentary";
    let reqOBJ = values;

    reqOBJ.requestId = props?.requestId;

    fetchData(reqURL, reqOBJ, (data) => {
      setLoading(false);
      if (data?.success) {
        message.success("Request updated successfully.");
        props.handleComplimentary();
      } else {
        message.error("Oops! Something went wrong.");
      }
    });
  };

  const setDefaultData = () => {
    form.setFieldsValue({
      isComplimentary: props?.isComplimentary || false,
    });
  };

  useEffect(() => {
    setDefaultData();
  }, []);

  return (
    <Form
      name="deny-request"
      form={form}
      {...layout}
      onFinish={onFinish}
      autoComplete="off"
      layout={"vertical"}
    >
      <Form.Item name="isComplimentary" valuePropName="checked">
        <Checkbox>Make Request Complimentary</Checkbox>
      </Form.Item>

      <Form.Item className="btn-row">
        <Button
          icon={<CheckOutlined />}
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          Confirm
        </Button>
      </Form.Item>
    </Form>
  );
};

export default MakeComplimentary;
