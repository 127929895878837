import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { Layout } from "antd";
import AppHeader from "./components/app-header";
import AppFooter from "./components/app-footer";
import SignIn from "./screens/signin";
import Signup from "./screens/signup";
import Dashboard from "./screens/dashboard";

function App() {
  const { Content } = Layout;
  const [isSignedIn, setIsSignedIn] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const isSignedIn = localStorage.getItem("token") ? true : false;
    setIsSignedIn(isSignedIn || "");
  }, [navigate, location.pathname]);

  return (
    <Layout>
      <AppHeader key={isSignedIn} isSignedIn={isSignedIn} />
      <Layout>
        <Content>
          <Routes>
            <Route path="*" element={<Dashboard />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<Signup />} />
          </Routes>
        </Content>
      </Layout>
      <AppFooter />
    </Layout>
  );
}

export default App;
