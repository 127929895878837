import React, { useState, useEffect } from "react";
import { Form, Button, message, InputNumber, Input } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import fetchData from "../services/requester";

const ExpenseAdd = (props) => {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 14,
    },
  };

  const onFinish = (values) => {
    setLoading(true);
    let reqURL = "requests/expense";
    let reqOBJ = values;

    reqOBJ.requestId = props?.requestId;

    fetchData(reqURL, reqOBJ, (data) => {
      setLoading(false);

      if (data?.success) {
        message.success("Expense added successfully.");
        props.handleExpense();
      } else {
        message.error("Oops! Something went wrong.");
      }
    });
  };

  const setDefaultData = () => {
    form.setFieldsValue({
      expensetoClient: props?.expensetoClient || 0,
      expensetoSupplier: props?.expensetoSupplier || 0,
      expenseType: props?.expenseType || "",
    });
  };

  useEffect(() => {
    setDefaultData();
  }, []);

  return (
    <Form
      name="expense-add"
      form={form}
      {...layout}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item label="To Client" name="expensetoClient">
        <InputNumber
          min={0}
          placeholder="Expense to Client"
          style={{ width: "100%" }}
        />
      </Form.Item>

      <Form.Item label="To Supplier" name="expensetoSupplier">
        <InputNumber
          min={0}
          placeholder="Expense to Supplier"
          style={{ width: "100%" }}
        />
      </Form.Item>

      <Form.Item label="Details" name="expenseType">
        <Input.TextArea
          placeholder="Expenses Details"
          style={{ minHeight: "92px" }}
        />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 12,
        }}
        className="btn-row"
      >
        <Button
          icon={
            props?.expensetoClient ||
            props?.expensetoSupplier ||
            props?.expenseType ? (
              <EditOutlined />
            ) : (
              <PlusOutlined />
            )
          }
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          {props?.expensetoClient ||
          props?.expensetoSupplier ||
          props?.expenseType
            ? "Update "
            : "Add "}
          Expense
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ExpenseAdd;
