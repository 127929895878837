import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { StopOutlined } from "@ant-design/icons";
import fetchData from "../services/requester";

const DenyRequest = (props) => {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const layout = {
    labelCol: {
      span: 22,
    },
    wrapperCol: {
      span: 22,
    },
  };

  const onFinish = (values) => {
    setLoading(true);
    let reqURL = "requests/deny";
    let reqOBJ = values;

    reqOBJ.requestId = props?.requestId;

    fetchData(reqURL, reqOBJ, (data) => {
      setLoading(false);

      if (data?.success) {
        message.success("Request denied successfully.");
        props.handleDeny();
      } else {
        message.error("Oops! Something went wrong.");
      }
    });
  };

  return (
    <Form
      name="deny-request"
      form={form}
      {...layout}
      onFinish={onFinish}
      autoComplete="off"
      layout={"vertical"}
    >
      <Form.Item
        label="Reason For Denied"
        name="reasonForDenied"
        rules={[
          {
            required: true,
            message: "Please input Reason For Denied!",
          },
        ]}
      >
        <Input.TextArea
          placeholder="Reason For Denied"
          style={{ minHeight: "92px" }}
        />
      </Form.Item>

      <Form.Item className="btn-row">
        <Button
          icon={<StopOutlined />}
          type="primary"
          htmlType="submit"
          loading={isLoading}
          danger
        >
          Confirm Deny
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DenyRequest;
