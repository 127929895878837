import React, { useState, useEffect } from "react";
import { Form, Button, message, InputNumber } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import fetchData from "../services/requester";

const QuotationAdd = (props) => {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const layout = {
    labelCol: {
      span: 7,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const onFinish = (values) => {
    setLoading(true);
    let reqURL = "requests/quotation";
    let reqOBJ = values;

    reqOBJ.requestId = props?.requestId;

    fetchData(reqURL, reqOBJ, (data) => {
      setLoading(false);

      if (data?.success) {
        props?.quoteFromSupplier
          ? message.success("Quote updated successfully.")
          : message.success("Quote added successfully.");
        props.handleQuotation();
      } else {
        message.error("Oops! Something went wrong.");
      }
    });
  };

  const setDefaultData = () => {
    form.setFieldsValue({
      quoteFromSupplier: props?.quoteFromSupplier || "",
    });
  };

  useEffect(() => {
    setDefaultData();
  }, []);

  return (
    <Form
      name="quotation-add"
      form={form}
      {...layout}
      onFinish={onFinish}
      autoComplete="off"
      // layout={"vertical"}
    >
      <Form.Item
        label="Quote"
        name="quoteFromSupplier"
        rules={[
          {
            required: true,
            message: "Please input Quote!",
          },
        ]}
      >
        <InputNumber min={0} placeholder="Quote" style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 7,
          span: 17,
        }}
        className="btn-row"
      >
        <Button
          icon={props?.driverName ? <EditOutlined /> : <PlusOutlined />}
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          {props?.driverName ? "Update" : "Add"} Quote
        </Button>
      </Form.Item>
    </Form>
  );
};

export default QuotationAdd;
