import React, { useState } from "react";
import { Form, Input, Button, Typography, message } from "antd";
import { useNavigate } from "react-router-dom";
import fetchData from "../services/requester";

const SignIn = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { Title } = Typography;

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const onFinish = (values) => {
    setLoading(true);
    let reqURL = "signin";
    let reqOBJ = values;

    fetchData(reqURL, reqOBJ, (data) => {
      setLoading(false);

      if (data?.token && data?.role) {
        localStorage.setItem("token", data?.token);
        localStorage.setItem("role", data?.role);
        localStorage.setItem("name", data?.name);
        navigate("/");
      } else {
        message.error(data?.message || "Email Id or Password Incorrect.");
      }
    });
  };

  const handleSignup = () => {
    navigate("/signup");
  };

  return (
    <div className="signin-box">
      <Title level={4} className="signin-box-title">
        Sign in
      </Title>

      <Form
        name="signin"
        autoComplete="off"
        onFinish={onFinish}
        className="signin-form"
        {...layout}
      >
        <Form.Item
          label="Email Id"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input Email Id!",
            },
          ]}
        >
          <Input placeholder="Email Id" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="Password"
          rules={[
            {
              required: true,
              message: "Please input Password!",
            },
          ]}
        >
          <Input type="password" placeholder="Password" />
        </Form.Item>

        <Form.Item
          wrapperCol={{ xs: { offset: 0 }, xl: { offset: 8 } }}
          className="btn-row"
        >
          <Button type="primary" htmlType="submit" loading={loading}>
            Sign in
          </Button>
          <Button type="default" onClick={handleSignup}>
            Sign up
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SignIn;
