import React, { useState } from "react";
import { Form, Input, Button, Select, message } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import fetchData from "../services/requester";

const RequestApproval = (props) => {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const layout = {
    labelCol: {
      span: 7,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const onFinish = (values) => {
    setLoading(true);
    let reqURL = "requests/confirm";
    let reqOBJ = values;

    reqOBJ.requestId = props?.requestId;

    fetchData(reqURL, reqOBJ, (data) => {
      setLoading(false);

      if (data?.success) {
        message.success("Request confirmed successfully.");
        props.handleConfirm();
      } else {
        message.error("Oops! Something went wrong.");
      }
    });
  };

  return (
    <Form
      name="request-approval"
      form={form}
      {...layout}
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{
        countryCode: "+91",
      }}
    >
      <Form.Item
        label="Title"
        name="travellerTitle"
        rules={[
          {
            required: true,
            message: "Please select Title!",
          },
        ]}
      >
        <Select placeholder="Select Title">
          <Select.Option value="Mr.">Mr.</Select.Option>
          <Select.Option value="Mrs.">Mrs.</Select.Option>
          <Select.Option value="Miss">Miss</Select.Option>
          <Select.Option value="Ms.">Ms.</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Name"
        name="travellerName"
        rules={[
          {
            required: true,
            message: "Please input Name!",
          },
        ]}
      >
        <Input placeholder="Name" />
      </Form.Item>

      <Form.Item
        label="Phone"
        name="travellerPhone"
        rules={[
          {
            required: true,
            message: "Please input Phone Number!",
          },
        ]}
      >
        <Input
          addonBefore={
            <Form.Item name="countryCode" noStyle>
              <Select style={{ width: 70 }}>
                <Select.Option value="+91">+91</Select.Option>
              </Select>
            </Form.Item>
          }
          placeholder="Phone"
        />
      </Form.Item>

      <Form.Item label="Email" name="travellerEmail">
        <Input placeholder="Email" />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 7,
          span: 17,
        }}
        className="btn-row"
      >
        <Button
          icon={<CheckOutlined />}
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          Confirm Request
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RequestApproval;
