import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, message } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import fetchData from "../services/requester";

const DriverDetails = (props) => {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const layout = {
    labelCol: {
      span: 7,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const onFinish = (values) => {
    setLoading(true);
    let reqURL = "requests/driver-details";
    let reqOBJ = values;

    reqOBJ.requestId = props?.requestId;

    fetchData(reqURL, reqOBJ, (data) => {
      setLoading(false);

      if (data?.success) {
        props?.driverName
          ? message.success("Driver details updated successfully.")
          : message.success("Driver details added successfully.");
        props.handleDriverDetails();
      } else {
        message.error("Oops! Something went wrong.");
      }
    });
  };

  const setDefaultData = () => {
    form.setFieldsValue({
      driverName: props?.driverName || "",
      driverPhone: props?.driverPhone || "",
      vehicleDetails: props?.vehicleDetails || "",
    });
  };

  useEffect(() => {
    setDefaultData();
  }, []);

  return (
    <Form
      name="driver-details-add"
      form={form}
      {...layout}
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{
        countryCode: "+91",
      }}
    >
      <Form.Item
        label="Driver Name"
        name="driverName"
        rules={[
          {
            required: true,
            message: "Please input Driver Name!",
          },
        ]}
      >
        <Input placeholder="Driver Name" />
      </Form.Item>

      <Form.Item
        label="Driver Phone"
        name="driverPhone"
        rules={[
          {
            required: true,
            message: "Please input Driver Phone Number!",
          },
        ]}
      >
        <Input
          addonBefore={
            <Form.Item name="countryCode" noStyle>
              <Select style={{ width: 70 }}>
                <Select.Option value="+91">+91</Select.Option>
              </Select>
            </Form.Item>
          }
          placeholder="Enter Driver Phone Number"
        />
      </Form.Item>

      <Form.Item
        label="Vehicle Details"
        name="vehicleDetails"
        rules={[
          {
            required: true,
            message: "Please input Vehicle Details!",
          },
        ]}
      >
        <Input placeholder="Vehicle Details" />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 7,
          span: 17,
        }}
        className="btn-row"
      >
        <Button
          icon={props?.driverName ? <EditOutlined /> : <PlusOutlined />}
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          {props?.driverName ? "Update" : "Add"} Driver Details
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DriverDetails;
